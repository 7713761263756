<template>
  <div class="column-center" style="background-color: #f8f8f8">
    <!-- <div
      class="div-background guide-background"
      style="background-image:url('{{ icons.backgroundRect }}')"
    ></div> -->

    <div class="guide-form-container column">
      <div
        class="div-background"
        :style="{
          alignSelf: 'center',
          height: '44px',
          width: '490px',
          backgroundImage: `url(${icons.join_guide_title})`,
        }"
      ></div>

      <div class="guide-title" style="margin-top: 40px; margin-bottom: 20px">
        巡展主题
      </div>

      <div class="guide-context">“追忆似水年华·去斯万家那边”</div>

      <div class="guide-title" style="margin-top: 30px; margin-bottom: 20px">
        评审标准
      </div>

      <div class="guide-context">
        观察力、思考力、想象力、创造力等综合能力考
      </div>
      <div class="guide-context">察，以及是都扣题目和作品美感表达。</div>

      <div class="guide-title" style="margin-top: 30px; margin-bottom: 20px">
        巡展日程
      </div>

      <div class="guide-context">报名及提交作品时间：</div>
      <div class="guide-context">2021年10月15日-12月30日</div>

      <div class="guide-context" style="margin-top: 30px">
        公布初选结果（优秀奖）时间：
      </div>
      <div class="guide-context">2022年1月5日前</div>
      <div class="guide-context">（公布形式：组委会与各报名机构通知）</div>

      <div class="guide-context" style="margin-top: 30px">
        公布复审结果（金奖/社会注目奖）时间：
      </div>
      <div class="guide-context">2022年1月18日</div>
      <div class="guide-context">（公布形式：官网和公众号公布名单）</div>

      <div class="guide-context" style="margin-top: 30px">
        法国巡展和特别大奖公布时间：
      </div>
      <div class="guide-context">2022年2月</div>

      <div class="guide-title" style="margin-top: 30px; margin-bottom: 20px">
        参赛对象
      </div>

      <div class="guide-context">3-14岁儿童。</div>

      <div style="font-size: 26px; color: #282828; margin-top: 30px">备注:</div>

      <div style="font-size: 26px; color: #282828; margin-top: 30px">
        比赛按3-5岁、6-7岁、8-9岁、10-12岁、13-14岁
      </div>

      <div style="font-size: 26px; color: #282828">分为5年龄组别。</div>
    </div>

    <!-- <div
      class="row"
      style="
        align-self: flex-start;
        font-size: 26px;
        margin-top: 30px;
        margin-left: 30px;
      "
    >
      <img
        @click="onChange"
        style="
          width: 15px;
          height: 15px;
          margin-right: 10px;
          cursor: pointer;
          user-select: none;
        "
        :src="
          isCheck ? icons.register_agree_checked : icons.register_agree_normal
        "
      />
      我已充分了解报名须知内容及参赛承诺
    </div> -->
    <div
      @click="onChange"
      class="row"
      style="
        font-size: 13px;
        margin-top: 30px;
        margin-left: 30px;
        cursor: pointer;
      "
    >
      <img
        style="
          width: 15px;
          height: 15px;
          margin-right: 10px;
          cursor: pointer;
          user-select: none;
        "
        :src="
          isCheck ? icons.register_agree_checked : icons.register_agree_normal
        "
      />
      我已充分了解报名须知内容及参赛承诺
    </div>

    <a-button
      @click="goNext"
      type="primary"
      size="large"
      style="
        width: 200px;
        margin-top: 50px;
        margin-bottom: 100px;
        border-radius: 20px;
        background: #295a9f;
        border-color: #295a9f;
      "
    >
      下一步
    </a-button>

    <div style="height: 100px"></div>
  </div>
</template>

<script>
import * as eventHelper from "../../utils/eventHelper";
export default {
  data() {
    return {
      icons: this.$root.icons,
      isCheck: false,
    };
  },
  mounted() {
    setTimeout(() => {
      eventHelper.triggerEvent("footer-change-background", {
        backgroundColor: "#062C3E",
        fontColor: "#ffffff",
      });
    }, 500);
  },
  methods: {
    onChange() {
      this.isCheck = !this.isCheck;
    },
    goNext() {
      if (this.isCheck) {
        this.$router.push({
          name: "enroll",
        });
      } else {
        this.$message.warn("请先阅读并同意参赛须知");
      }
    },
  },
};
</script>

<style scoped>
.guide-form-container {
  margin-top: 100px;
  width: 710px;
  border-radius: 10px;
  background-color: white;
  padding: 45px 30px;
}

.guide-title {
  border-left: 6px solid #ff6262;
  padding-left: 12px;
  font-size: 26px;
  line-height: 26px;
  font-weight: bold;
}

.guide-context {
  align-self: center;
  font-size: 28px;
  font-weight: bold;
  color: #282828;
}
</style>